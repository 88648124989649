import React from "react"
import { Link } from "gatsby"
import Button from "react-bootstrap/Button"

import Layout from "../components/Layout"
import Heading from "../components/Layout/Heading"
import SEO from "../components/SEO"
import Icon from "../components/Icon"

const NotFoundPage = ({ location }) => (
  <Layout location={location}>
    <SEO title="Page Not found" description="Page not found" />
    <Heading title="Not Found" />
    <p>Sorry, the requested URL can't be found</p>
    <Link to="/">
      <Button bsPrefix="stu">
        Homepage <Icon prefix="fas" name="home" />
      </Button>
    </Link>
    <p></p>
  </Layout>
)

export default NotFoundPage
